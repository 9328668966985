<template>
  <div id="app">
    <b-row class="top_bar" no-gutters>
      <b-col
        class="top_bar__left d-flex justify-content-center align-items-center flex-column"
        cols="12"
        md="6"
      >
        <img
          alt="Vue logo"
          src="./assets/imgs/logo2.png"
          class="cursor--pointer"
          @click="reloadPage"
        />
        <small class="cursor--pointer" @click="reloadPage"
          >Vandaag al 213 offerteaanvragen</small
        > </b-col
      ><b-col
        class="top_bar__right d-flex justify-content-center align-items-center flex-column"
        cols="12"
        md="6"
      >
        <p class="mb-0">Super eenvoudig: Besparen op zonnepanelen</p>
        <p class="mb-0 top_bar__right-bottom-text">
          was nog nooit zo makkelijk!
          <span> Laat de zon voor jou werken </span>
        </p></b-col
      ></b-row
    >
    <div class="main">
      <router-view />
    </div>

    <div
      class="footer p-md-4 p-3 d-flex justify-content-center align-items-center"
    >
      <p class="mb-0 footer-text">© Belgischadvies.be</p>
      <p class="mb-0 footer-text ml-2" @click="showPrivacyModal">
        Privacy Verklaring
      </p>
      <p class="mb-0 footer-text ml-2" @click="showActionTermsModal">
        Actievoorwaarden
      </p>

      <b-modal
        v-model="privacyModalVisible"
        title="Privacy Verklaring"
        size="lg"
        centered
        ok-only
        ok-variant="warning"
        scrollable
      >
        <template #modal-title>
          <h3 class="modal-title">Privacy beleid</h3>
        </template>
        <PrivacyPolicy />
      </b-modal>

      <b-modal
        v-model="actionTermsModalVisible"
        title="Actievoorwaarden"
        size="lg"
        centered
        ok-only
        ok-variant="warning"
        scrollable
      >
        <template #modal-title>
          <h3 class="modal-title">Algemene voorwaarden</h3>
        </template>
        <TermsConditions />
      </b-modal>
    </div>
  </div>
</template>
<script>
import PrivacyPolicy from "./components/PrivacyPolicy.vue";
import TermsConditions from "./components/TermsConditions.vue";
export default {
  data() {
    return {
      privacyModalVisible: false,
      actionTermsModalVisible: false,
    };
  },
  components: {
    PrivacyPolicy,
    TermsConditions,
  },
  methods: {
    showPrivacyModal() {
      this.privacyModalVisible = true;
    },
    showActionTermsModal() {
      this.actionTermsModalVisible = true;
    },
    reloadPage() {
      if (window.location.pathname.includes("/bedankt")) {
        // Navigate to the new page
        window.location.assign("/");
      } else {
        // Reload the page
        window.location.reload();
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700;800;900&family=DM+Sans&display=swap");

h1,
p,
span {
  font-family: "Catamaran", sans-serif;
}

#app {
  display: flex !important;
  flex-direction: column !important;
  min-height: 100vh !important;
  background: #ffe686 !important;
}
.main {
  flex-grow: 1 !important;
}

@for $i from 10 through 96 {
  .fs--#{$i} {
    font-size: $i + px !important;
    @media (max-width: 600px) {
      font-size: $i - 3 + px !important;
    }
  }
  .fs--md--#{$i} {
    font-size: $i + px !important;
    @media (max-width: 991.98px) {
      font-size: $i/2 + 8 + px !important;
    }
    @media (max-width: 767.98px) {
      font-size: $i/2 + 5 + px !important;
    }
  }
}
@for $i from 1 through 9 {
  .fw--#{$i*100} {
    font-weight: $i * 100;
  }
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
* {
  scroll-behavior: smooth !important;
}
.cursor--pointer {
  cursor: pointer;
}
.primary--btn {
  background: #1d8eea !important;
  border-color: #1d8eeaca !important;
  text-transform: uppercase;
  &:hover {
    background: #0785ec !important;
  }
}
.primaryred--btn {
  background: #ff0032 !important;
  border-color: #ff0032 !important;
  text-transform: uppercase;
  &:hover {
    background: #ff0032 !important;
  }
}
.primary--text {
  color: #ff0032 !important;
}
.text--uppercase {
  text-transform: uppercase;
}

.top_bar {
  // height: 120px;
  // height: 100px;

  &__left {
    background: #1d1729;
    height: 100px;

    img {
      width: 250px;
    }
    small {
      // font-size: 16px;
      font-size: 13px;
      letter-spacing: 0px;
      margin-left: 20px;
      color: #fefefe;
      font-weight: 400;
      margin-left: 57px;
    }

    @media (max-width: 767.99px) {
      height: 80px !important;
      img {
        width: 200px;
      }
      small {
        // font-size: 16px;
        font-size: 11px;
        margin-left: 38px;
      }
    }

    @media (max-width: 575.99px) {
      height: 70px !important;

      img {
        width: 180px;
      }
      small {
        font-size: 10px;
        margin-left: 30px;
      }
    }

    @media (max-width: 399.99px) {
      img {
        width: 170px;
      }
      small {
        font-size: 8px;
        margin-left: 50px;
      }
    }
  }
  &__right {
    background: #ffffff;
    height: 100px;

    p {
      // font-size: 18px;
      font-size: 15px;
      color: #1d1729;
      // font-weight: 800;
      font-weight: 700;
      // font-family: "Catamaran";
      span {
        color: #ffcc01 !important;
      }
    }

    &-bottom-text {
      margin-right: 83px;
    }

    @media (max-width: 1109px) {
      p {
        font-size: 13px;
      }

      &-bottom-text {
        margin-right: 67px;
      }
    }

    @media (max-width: 930px) {
      p {
        font-size: 12px;
      }

      &-bottom-text {
        margin-right: 60px;
      }
    }

    @media (max-width: 767.99px) {
      height: 80px !important;
    }
    @media (max-width: 575.99px) {
      height: 70px !important;

      p {
        font-size: 10px;
      }
    }

    @media (max-width: 399.99px) {
      p {
        font-size: 8px;
      }
      &-bottom-text {
        margin-right: 45px;
      }
    }
  }
}

.footer {
  background: #ffffff !important;
  &-text {
    font-size: 16px;
    line-height: 30px;
    color: #1d1729;
    font-weight: 400;
    cursor: pointer;
  }

  @media (max-width: 450px) {
    flex-direction: column !important;
    p {
      margin-left: 0px !important;
    }
  }
}

.question-card {
  border-radius: 30px;
  filter: drop-shadow(0 0 13.5px rgba(0, 0, 0, 0.21));
  background-color: #ffffff;
  .back-arrow {
    // position: absolute;
    width: 25px;
    // transform: rotate(180deg);
    // top: 25px;
    // left: 20px;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
    }
  }

  &__progress {
    // font-size: 16px;
    font-size: 14px;
    color: #ffcc01;
    font-weight: 600;
  }

  &__question {
    color: #1d1729;
    // font-size: 45px;
    font-size: 35px;
    // font-weight: 900;
    font-weight: 800;
    line-height: 40px;
  }

  &__right-col {
    border-radius: 0px 30px 30px 0px;

    &-text {
      background-color: #ffe786;
      padding: 5px;
      width: 100% !important;
      top: 35px;
      span {
        // font-size: 16px;
        font-size: 14px;
        color: #1d1729;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  &__left-col {
    padding: 80px 50px !important;
  }

  .form-control {
    background-color: #ffffff !important;
    border: 1px solid #cccbcb !important;
    // font-size: 22px;
    font-size: 16px;
    letter-spacing: 2px;
    // color: #bbbabc;
    color: #495057 !important;
    font-weight: 600;
    border-radius: 0px;
  }

  .custom-select {
    background-color: #ffffff !important;
    border: 1px solid #cccbcb !important;
    // font-size: 22px;
    font-size: 16px !important;
    letter-spacing: 2px !important;

    color: #495057;
    font-weight: 600 !important;
    border-radius: 0px !important;
  }

  .custom-select:focus {
    color: #495057 !important;
  }

  .btn {
    border-radius: 37px;

    background-color: #ffcc01;
    padding: 10px;

    font-size: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: 900;
    border: none;
    &:hover,
    :active {
      background-color: #ffcc01 !important;
      color: #1d1729 !important;
    }
  }
  .btn:active,
  .btn:focus {
    background-color: #ffcc01 !important;
    color: #1d1729 !important;
    border: none !important;
  }

  .check-item {
    background-color: #ffffff;
    border: 1px solid #cccbcb;
    padding: 9px 12px;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  .custom-control-label {
    // font-size: 22px;
    // font-size: 16px;
    font-size: 15px;
    letter-spacing: 1px;
    // line-height: 33px;
    line-height: 24px;
    color: #1d1729;
    font-weight: 600;
    cursor: pointer !important;
  }

  .custom-control-label::before {
    // top: 0.5rem;
    background: #ffcc01;
    border: none !important;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none !important;
  }

  @media (max-width: 999.99px) {
    &__left-col {
      padding: 60px 30px !important;
    }

    &__question {
      font-size: 30px;
    }

    &__progress {
      font-size: 12px;
    }

    .custom-control-label {
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 25px;
    }
    .custom-control-label::before {
      top: 0.25rem;
    }

    &__right-col {
      &-text {
        span {
          font-size: 13px;
        }
      }
    }
  }

  @media (max-width: 767.99px) {
    &__left-col {
      padding: 50px 20px !important;
    }

    &__question {
      font-size: 25px !important;
      line-height: 30px;
    }

    &__progress {
      font-size: 12px;
    }

    .form-control {
      font-size: 14px;
    }

    .btn {
      font-size: 13px;
      letter-spacing: 1px;
    }

    .custom-control-label {
      font-size: 13px;
    }
    &__right-col {
      &-text {
        span {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 575.99px) {
    &__left-col {
      padding: 40px 15px !important;
    }

    &__question {
      font-size: 20px !important;
      line-height: 25px;
    }

    &__progress {
      font-size: 11px;
    }

    .form-control {
      font-size: 10px;
      letter-spacing: 1px;
    }

    .btn {
      font-size: 12px;
      letter-spacing: 1px;
    }

    .custom-control-label {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.3px;
    }
    &__right-col {
      &-text {
        span {
          font-size: 10px;
        }
      }
    }
  }
}

.form-card {
  .custom-control-label {
    // font-size: 22px !important;
    font-size: 18px !important;
    letter-spacing: 1px !important;
    color: #bbbabc;
    font-weight: 600 !important;
  }
  input[type="radio"]:checked + label {
    color: #495057 !important;
  }

  .custom-radio {
    margin-right: 2rem !important;
  }
  .form-control {
    background-color: #ffffff !important;
    border: 1px solid #cccbcb !important;
    // font-size: 22px;
    font-size: 16px;
    letter-spacing: 2px;
    color: #495057;
    font-weight: 600;
    border-radius: 0px;
  }
  .custom-control-input[type="radio"] ~ .custom-control-label::before {
    top: 0.4rem !important;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    display: none !important;
  }

  @media (max-width: 991.99px) {
    .custom-control-label {
      font-size: 16px !important;
    }

    .form-control {
      font-size: 14px;
    }
    .custom-control-input[type="radio"] ~ .custom-control-label::before {
      top: 0.5rem !important;
      width: 13px !important;
      height: 13px !important;
    }
  }
  @media (max-width: 767.99px) {
    .custom-control-label {
      font-size: 12px !important;
    }

    .form-control {
      font-size: 12px;
    }
    .custom-control-input[type="radio"] ~ .custom-control-label::before {
      top: 0.3rem !important;
      width: 12px !important;
      height: 12px !important;
    }
  }

  @media (max-width: 575.99px) {
    .custom-control-label {
      font-size: 10px !important;
    }

    .form-control {
      font-size: 10px;
    }
    .custom-control-input[type="radio"] ~ .custom-control-label::before {
      top: 0.1rem !important;
      width: 11px !important;
      height: 11px !important;
    }
  }
  @media (max-width: 448.99px) {
    .custom-control-label {
      font-size: 11px !important;
    }

    .form-control {
      font-size: 10px;
    }
    .custom-control-input[type="radio"] ~ .custom-control-label::before {
      top: 0.1rem !important;
      width: 10px !important;
      height: 10px !important;
    }
  }
}

.form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #bbbabc !important;
}
.form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #bbbabc !important;
}
.form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #bbbabc !important;
}
.form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #bbbabc !important;
}

.multiselect__tags {
  border-radius: 0px !important;
  background-color: #ffffff !important;
  border: 1px solid #cccbcb !important;
  font-size: 16px !important;
  letter-spacing: 2px !important;
  color: #495057 !important;
  font-weight: 600 !important;
}
.multiselect__single > strong {
  font-weight: 600 !important;
}
.multiselect__single {
  font-size: 16px !important;
  letter-spacing: 2px !important;
  color: #495057 !important;
  font-weight: 600 !important;
}

@media (max-width: 767.99px) {
  .multiselect__tags {
    font-size: 14px !important;
  }
  .multiselect__single > strong {
    font-size: 14px !important;
  }
  .multiselect__single {
    font-size: 14px !important;
  }
}
@media (max-width: 575.99px) {
  .multiselect__tags {
    font-size: 10px !important;
    letter-spacing: 1px !important;
  }
  .multiselect__single > strong {
    font-size: 10px !important;
    letter-spacing: 1px !important;
  }
  .multiselect__single {
    font-size: 10px !important;
    letter-spacing: 1px !important;
  }
}
</style>
