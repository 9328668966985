import axios from "axios";
import authHeader from "./AuthHeader";

const API_URL = "https://leadgen.republish.nl/api";

class LeadService {
  createLead(payload) {
    return axios.post(API_URL + "/sponsors/2249/leads", payload, {
      headers: authHeader(),
    });
  }
}

export default new LeadService();
