<template>
  <div>
    <div class="col-lg-12 section-row-col-class-456135" style="">
      <div class="space-1 section-row-col-content-class-542326" style="">
        
        <p>
          Dit privacybeleid is van toepassing op alle deelnemers van onderhavige campagne van Solvari (hierna te noemen: “Opdrachtgever”).<br />
        </p>
        <p>
          De uitvoering van deze campagne wordt namens de Opdrachtgever verzorgd door Re:publish (hierna te noemen Re:publish of Organisator). Re:publish vind uw privacy is belangrijk. Re:publish vindt dat u erop moet kunnen vertrouwen dat uw persoonlijke informatie in overeenstemming met de hoogste ethische en juridische normen behandelen. In deze Privacy Policy wordt beschreven hoe persoonlijke informatie verzameld en verwerkt wordt, uiteraard rekening houdend met uw toestemming indien deze wettelijk is vereist.
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Verzamelde Gegevens</h2>
        <p>Wij verwerken uw persoonsgegevens op basis van de door u verstrekte informatie en uw uitdrukkelijke toestemming bij het verzenden van het formulier. De persoonsgegevens die wij verwerken, worden door u ingevuld. Daarnaast registreren wij de volgende gegevens bij het verzenden van het formulier:</p>
       <ul>
       <li>Datum en tijd van aanmelding/registratie </li>
        <li>IP-adres</li>
        <li>Website van aanmelding/registratie</li>
        </ul>
        <p>
          Wij streven er naar geen gegevens te verzamelen van bezoekers jonger dan 18 jaar. Hoewel we ons best doen om dit te controleren, kunnen wij die niet altijd garanderen. Als u een vermoeden heeft dat wij gegevens hebben verzamelend van een persoon jonger dan 18 jaar, neem dan contact met ons op zodat wij deze gegevens kunnen verwijderen. 
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542328">
        <h2 data-title="Persoonsgegevens" id="persoonsgegevens">
          Persoonsgegevens die wij verwerken
        </h2>

      <p>Wij verzamelen bovengenoemde contactgegevens om:</p>
      <ul>
      <li>Om telefonisch, per e-mail of per post contact met u op te nemen met over producten of diensten met betrekking tot onderhavige campagne;</li>
      <li>U marketingcommunicatie te sturen, indien u hiermee instemt.</li>
      <li>Indien van toepassing: om prijswinnaars te selecteren en informeren bij het uitreiken van prijzen.</li>
      </ul>
        
         <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Rechtsgrondslag</h2>
          <p>
          Wij verwerken uw gegevens op basis van uw toestemming.
        </p>
        <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Hoe worden gegevens verzameld:</h2>
        <p>
        Uw gegevens worden verzameld via online formulieren op onze campagnepagina.
        </p>
         <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Delen van Gegevens</h2>
        <p>
        Vergaarde persoonlijke informatie zal alleen door Re:publish en Opdrachtgever gebruikt worden voor de hierboven omschreven doeleinden. Opdrachtgever kan, indien nodig, een derde partij inschakelen om uw persoonsgegevens te verwerken in overeenstemming met de eerder genoemde doeleinden, bijvoorbeeld een callcenter of marketingbureau, zodat zij contact met u kunnen opnemen. Hoe de Opdrachtgever met uw gegevens omgaat, is vastgelegd in de privacyverklaring van de betreffende Opdrachtgever.
        </p>
        <p>
        Re:publish verstrekt persoonlijke informatie niet aan niet-geautoriseerde derden.
        </p>
         <hr class="my-0 mt-7" />
      </div>

       <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Bewaartermijn</h2>
        <p>
        Re:publish bewaart uw gegevens zolang als nodig is voor de doeleinden waarvoor ze zijn verzameld.
        </p>
         <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Recht van Betrokkenen</h2>
        <p>
        U heeft het recht om uw gegevens in te zien, te corrigeren of te verwijderen. Neem contact met ons op voor verzoeken met betrekking tot uw gegevens. Ook heeft u het recht om op elk moment uw toestemming voor gegevensverwerking in te trekken. U kunt dergelijk verzoek schriftelijk indienen bij de Opdrachtgever. 
        </p>
         <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Cookies en Tracking</h2>
        <p>
        Bij uw bezoek aan onze website is het van belang om bepaalde informatie te verzamelen om de verbinding soepel te laten verlopen. Wanneer u een webpagina opvraagt, dient de webserver te weten waar de pagina naartoe moet worden gestuurd. Hiervoor wordt gebruik gemaakt van uw IP-adres, dat automatisch wordt toegewezen door uw internetprovider telkens wanneer u online gaat. Dit IP-adres wordt gebruikt om uw computer te identificeren.
        </p>

        <p>
        Re:publish kan persoonlijke gegevens, zoals uw Internet Protocol (IP) adres, opslaan wanneer u onze website(s) bezoekt. Wij behandelen deze informatie in overeenstemming met de geldende privacywetgeving. Deze gegevens worden uitsluitend gebruikt voor het beantwoorden van uw vragen, het analyseren van het gedrag van bezoekers op onze website en voor marketingdoeleinden.
        </p>
        <p>
       <b> Wat is een cookie dan precies? </b> <br>
        Een cookie is een eenvoudig klein bestandje dat met de pagina's van onze website wordt meegestuurd en door uw browser op de harde schijf van uw computer wordt opgeslagen. De informatie die in deze cookies wordt opgeslagen, kan bij een volgend bezoek weer naar onze servers worden teruggestuurd.De gegevens die wij via deze cookies verkrijgen, stellen ons in staat om u aanbiedingen en advertenties te tonen die aansluiten bij uw interesses.
        </p>
         <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Externe Links</h2>
        <p>
        Onze website kan links naar andere websites bevatten. We zijn niet verantwoordelijk voor het privacybeleid of de inhoud van deze andere websites. We raden u aan om de privacyverklaringen op deze websites te lezen. 
        </p>
         <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Wijzigingen in het Beleid</h2>
        <p>
        Dit beleid kan worden bijgewerkt in verband met veranderende wetgeving. Re:publish behoudt zich het recht voor om dit op elk moment en zonder voorafgaande kennisgeving te kunnen doen. Bekijk regelmatig ons privacybeleid voor eventuele wijzigingen. 
        </p>
         <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Juridische Basis en Rechtsgebied:</h2>
        <p>
        Dit privacybeleid is onderworpen aan het Nederlands recht. Eventuele geschillen worden voorgelegd aan de bevoegde rechtbanken in Nederland. 
        </p>
         <hr class="my-0 mt-7" />
      </div>

      <div class="space-1 section-row-col-content-class-542327" style="">
        <h2 data-title="Privacybeleid" id="privacybeleid">Re:publish BV</h2>
        <ul class="list-unstyled">
          <li>Weena 690, verdieping 23, kantoor 7</li>
          <li>312CN Rotterdam</li>
          <li>Nederland</li>
          <li><a href='mailto:info@republish.nl' target='blank'>info@republish.nl</a></li>
          <li>KVK: 85369780</li>
        </ul>
         <hr class="my-0 mt-7" />
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>
