<template>
  <div>
    <div class="col-lg-12 section-row-col-class-433173" style="">
      <div class="space-1 section-row-col-content-class-508418" style="">

        <ul>
        <li>Deze algemene voorwaarden zijn van toepassing op alle deelnemers aan bezochte campagne/actie. Deelname aan de campagne impliceert de volledige aanvaarding van deze voorwaarden. </li>
        <li>Deze campagne is geïnitieerd door <b> Solvari </b> (hierna te noemen: "Opdrachtgever") en wordt uitgevoerd in opdracht van Opdrachtgever door Re:publish BV (Re:publish is een conversie marketing netwerk, hierna te noemen Re:publish of Organisator).</li>
        <li>Deelname aan deze campagne is kosteloos, tenzij anders vermeld.</li>
        <li>De campagne is geldig t/m 31-12-2023</li>
        <li>Deelname aan de campagne staat open voor personen ouder dan 18 jaar. </li>
        <li>Door uw gegevens in te vullen en online te verzenden, geeft u blijk van interesse in de dienst, service en/of producten zoals beschreven in deze campagne, die worden aangeboden door de Opdrachtgever.</li>
        <li>Door deel te nemen, stemt de deelnemer in met het verzamelen en verwerken van hun persoonsgegevens. De gegevens die door een deelnemer worden verstrekt, worden opgeslagen in een gegevensbestand van Re:publish. Re:publish behandelt uw gegevens met uiterste zorgvuldigheid zoals beschreven in het privacybeleid. </li>
        <li>U stemt ermee in dat Re:publish uw gegevens verwerkt en deze beschikbaar stelt aan de Opdrachtgever, zodat de Opdrachtgever contact met u kan opnemen via telefoon of e-mail met betrekking tot de in de campagne beschreven dienst, service en/of producten.</li>
        <li>Deelname aan de campagne is op eigen risico. Re:publish aanvaardt geen aansprakelijkheid voor eventuele schade die voortvloeit uit de deelname aan de campagne, noch voor technische storingen, gebreken of vertragingen. Evenmin aanvaardt Re:publish aansprakelijkheid voor mogelijke problemen of gebreken die voortvloeien uit het gebruik van de campagnepagina(s) en/of het downloaden van enige componenten met betrekking tot de campagne.</li>
        <li>Re:publish behoudt zich het recht voor om te allen tijde en naar eigen inzicht deze voorwaarden te wijzigen of aan te passen tijdens de looptijd van de campagne, zonder nadelige gevolgen voor de deelnemer. </li>
        <li>Re:publish kan ook zonder opgaaf van reden de campagne beëindigen, wijzigen of aanpassen om moverende redenen, zonder enige verplichting tot schadevergoeding jegens de deelnemer.</li>
        <li>Indien een of meer bepalingen van deze voorwaarden nietig of ongeldig worden verklaard, heeft dit geen invloed op de geldigheid van de overige bepalingen van deze voorwaarden.</li>
        <li>Deze voorwaarden zijn onderworpen aan het Nederlands recht. Eventuele geschillen worden voorgelegd aan de bevoegde rechtbanken in Nederland.  </li>
        <li>Voor vragen, klachten op opmerkingen over de campagne, kunt u contact opnemen via:</li>
        </ul>

        <hr class="my-1 mt-7" />

        <h2 data-title="Privacybeleid" id="privacybeleid">Re:publish BV</h2>
        <ul class="list-unstyled">
          <li>Weena 690, verdieping 23, kantoor 7</li>
          <li>312CN Rotterdam</li>
          <li>Nederland</li>
          <li><a href='mailto:info@republish.nl' target='blank'>info@republish.nl</a></li>
          <li>KVK: 85369780</li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
};
</script>
