<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <!-- First section with 70% width -->
        <img
          src="@/assets/imgs/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />

        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          Wat zijn jouw postcode en huisnummer?
        </p>

        <b-form @submit="handleNextQuestion">
          <div class="row no-gutters first-question">
            <div class="col-lg-10 col-12">
              <div class="row no-gutters">
                <div class="col-7 pr-1">
                  <b-form-input
                    v-model="details.zip"
                    placeholder="Postcode"
                    required
                    :state="details.zip ? zipValidation : null"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!zipValidation">
                    Voer een geldige postcode in
                  </b-form-invalid-feedback>
                </div>
                <div class="col-5 pl-1">
                  <b-form-input
                    v-model="details.house_number"
                    placeholder="Huisnr."
                    required
                    :state="details.house_number ? houseNumberValidation : null"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!houseNumberValidation">
                    Voer een geldige huisnr in
                  </b-form-invalid-feedback>
                </div>
                <!-- <div class="col-12 mt-3">
                  <b-form-input
                    v-model="details.street"
                    placeholder="Straatnaam."
                    required
                    :state="details.street ? streetValidation : null"
                  ></b-form-input>

                  <b-form-select
                    v-model="details.street"
                    autocomplete="true"
                    required
                    :class="[
                      'mb-3',
                      details.street === '' ? 'street-dropdown' : '',
                    ]"
                  >
                    <b-form-select-option value="" disabled
                      >Straatnaam.</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="option in allStreets"
                      :value="option.street"
                      :key="option.street"
                      >{{ option.street }}</b-form-select-option
                    >
                  </b-form-select>
                  <b-form-invalid-feedback v-if="!streetValidation">
                    Voer een geldige straatnaam in
                  </b-form-invalid-feedback>
                </div> -->
                <div class="mt-3 auto-complete">
                  <!-- <multiselect
                    v-model="details.street"
                    :options="options"
                    placeholder="Straatnaam"
                  >
                  </multiselect> -->

                  <multiselect
                    v-model="selectedStreet"
                    :options="streetItems"
                    placeholder="Straatnaam."
                    :allow-empty="true"
                    selectLabel="Druk op enter om te selecteren"
                    deselectLabel="Druk op enter om te verwijderen"
                    @search-change="searchOnChange"
                  >
                    <!-- <template slot="singleLabel" slot-scope="{ option }">
                      <strong>{{ option.street }}</strong>
                    </template>
                    <template slot="option" slot-scope="{ option }">
                      {{ option.street }}
                    </template> -->
                    <template slot="noResult"
                      >Straatnaam niet gevonden</template
                    >
                    <template slot="noOptions">Straten niet gevonden</template>
                  </multiselect>
                  <p class="invalid-text mb-0" v-if="invalidStreet">
                    Vul alstublieft dit veld in
                  </p>
                </div>
                <!-- <div class="col-5 pl-1 mt-3">
                  <b-form-input
                    v-model="details.city"
                    placeholder="Plaatsnaam."
                    required
                    :state="details.city ? cityValidation : null"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!cityValidation">
                    Voer een geldige plaatsnaam in
                  </b-form-invalid-feedback>
                </div> -->
              </div>

              <b-button class="mt-3 w-100" type="submit">
                START DAKSAN
              </b-button>
            </div>
          </div>
        </b-form>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col"> </b-col>
    </b-row>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "FirstQuestion",
  data() {
    return {
      zipRegex: /^([0-9]{4})$/,
      houseRegex: /^[0-9A-Za-z\s\-]+$/,
      streetRegex: /^[a-zA-Z ]*$/,
      selected: null,
      options: [],
      selectedStreet: "",
      invalidStreet: false,

      streetItems: [],
    };
  },
  components: { Multiselect },

  props: {
    details: {
      type: Object,
      required: true,
    },

    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleNextQuestion(e) {
      e.preventDefault();

      if (this.details.street == "" || this.details.street == null) {
        this.invalidStreet = true;
        setTimeout(() => {
          this.invalidStreet = false;
        }, 3000);
      } else if (this.isValidForm) {
        const houseNumberWithoutPunctuation = this.details.house_number.replace(
          /[\s-]/g,
          ""
        );
        this.getAddress({
          zip: this.details.zip,
          house_number: houseNumberWithoutPunctuation,
          street: this.details.street,
        });
        this.$emit("nextQuestion", 2);
      }
    },
    goToPreviousPage() {
      this.$emit("previousPage");
    },

    searchOnChange(street) {
      if (street) {
        this.getStreets({ street: street, zip: this.details.zip });
      }
    },
  },
  computed: {
    zipValidation() {
      return this.zipRegex.test(this.details.zip) || this.details.zip === "";
    },
    houseNumberValidation() {
      return (
        this.houseRegex.test(this.details.house_number) ||
        this.details.house_number === ""
      );
    },
    streetValidation() {
      return (
        this.streetRegex.test(this.details.street) || this.details.street === ""
      );
    },
    cityValidation() {
      return (
        this.streetRegex.test(this.details.city) || this.details.city === ""
      );
    },
    isValidForm() {
      return (
        this.zipValidation && this.houseNumberValidation
        // this.streetValidation
      );
    },
  },

  watch: {
    selectedStreet(newStreet) {
      if (newStreet) {
        this.details.street = newStreet;
      } else {
        this.details.street = "";
      }
    },

    // Add your new watch property here
    allStreets: {
      handler(newStreetValue, oldStreetValue) {
        this.details.street = "";
        this.selectedStreet = "";
        if (newStreetValue.length > 0) {
          this.streetItems = newStreetValue.map((item) => {
            return item.street;
          });
        } else {
          this.streetItems = [];
        }
      },
      immediate: true, // Trigger the watch immediately on component mount
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-image: url(../../assets/imgs/firstQuestion.png);
    background-size: 100% 100% !important;
  }
}

.street-dropdown {
  color: #bbbabc !important;
}

.auto-complete {
  width: 100% !important;
}

.invalid-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
